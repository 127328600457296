.custom-color {
    color: #929292;
}
.field.is-horizontal {
    display: flex;
    justify-content: center;
    /* Rata tengah secara horizontal */
    align-items: center;
    /* Rata tengah secara vertikal */
    text-align: center;
    /* Pastikan teks juga rata tengah */
}

.field-label,
.field-body {
    flex-basis: 50%;
    /* Masing-masing bagian mengambil 50% lebar */
    display: flex;
    justify-content: center;
    /* Rata tengah di dalam setiap kolom */
    align-items: center;
    /* Vertikal rata tengah */
}

/* Modal container */
.modal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1050;
    /* Ensure it’s above other content */
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

/* Modal card */
.modal-card {
    background-color: white;
    padding: 20px;
    border-radius: 6px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Optional: Adds a subtle shadow */
    position: relative;
    /* Ensure relative positioning */
}

/* Top blue bar */
.modal-card>div:first-child {
    background-color: #005EB8;
    height: 2.5rem;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: absolute;
    /* Ensure top bar stays within modal-card */
    top: 0;
    left: 0;
}