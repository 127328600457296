/* Styling untuk elemen pencarian dan panjang tabel */
.dt-search {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Nunito', sans-serif;
}

.dt-length {
  float: left;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Nunito', sans-serif;
}

/* Styling untuk kontainer tabel */
.table-container {
  overflow-x: auto;
}

/* Styling untuk tabel */
.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  text-align: center;
  vertical-align: middle;
}

/* Styling untuk header tabel */
.table thead {
  background-color: #005EB8 !important;
  color: white !important;
  font-size: 14px !important;
  text-align: center;
  vertical-align: middle;
}

/* Styling untuk baris tabel yang ganjil */
.table tbody tr:nth-child(odd) {
  background-color: #D7D7D7 !important;
}

/* Styling untuk baris tabel yang genap */
.table tbody tr:nth-child(even) {
  background-color: white !important;
}

/* Styling untuk baris tabel saat hover */
.table tbody tr:hover {
  background-color: #f1f1f1 !important;
}

/* Styling untuk sel tabel */
.table td {
  /* background-color: white !important; */
  color: #333 !important;
  text-align: center;
  border: 1px solid #dbdbdb;
  /* Thin gray border */
  padding: 8px;
  vertical-align: middle;
}

.table th {
  background-color: #005EB8 !important;
  /* Ensure header background color is applied */
  color: #ffffff !important;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  border: 1px solid #dbdbdb;
  /* Thin gray border for header cells */
}

/* Styling untuk pagination */
.pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-button {
  background-color: #005EB8;
  /* Warna latar belakang tombol */
  color: white;
  /* Warna teks tombol */
  padding: 10px 20px;
  /* Padding tombol */
  border: none;
  /* Menghilangkan border */
  border-radius: 4px;
  /* Menambahkan sudut melengkung */
  cursor: pointer;
  /* Mengubah kursor saat hover */
  font-size: 16px;
  /* Ukuran font */
  text-align: center;
  /* Menyelaraskan teks di tengah */
  display: inline-block;
  /* Menampilkan tombol dalam baris */
  transition: background-color 0.3s ease;
  /* Transisi warna latar belakang */
}