.event-info-title {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    font-weight: bold;
    text-align: center;
}

.event-info-label {
    font-size: 1.2rem;
    margin-bottom: 1.25rem;
    font-weight: 600;
    text-align: center;
}

.event-info-input {
    display: flex;
    justify-content: center;
    margin: 0 2rem;
}

.event-info-input .input {
    text-align: center;
    width: 100%; /* Menjadikan field lebih besar dan dapat disesuaikan */
    max-width: 25rem; /* Membatasi ukuran maksimum jika diperlukan */
    font-size: 1rem; /* Sesuaikan ukuran teks agar konsisten */
}

.event-info-action {
    display: flex;
    justify-content: center;
}



