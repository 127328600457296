.input{
    margin-bottom: 10px;
    width: 10px;
}
.control{
    margin-bottom: 10px;
}
.radio-container {
    display: flex;
    align-items: center;
}

.radio.is-large {
    margin-right: 10px; /* Menambahkan jarak antara tombol radio */
}

.radio-text {
    font-size: 1.2rem; /* Menyesuaikan ukuran teks */
    margin-left: 5px; /* Menambahkan jarak antara teks dan tombol radio */
}

.kelamin{
    margin-top: -25px;
    margin-bottom: 18px;
}
.fieldd{
    margin-top: -35px;
}

.lokasigereja{
    margin-top: -15px;
}

.border{
    /* border: 2px solid #d6d6d6; Garis tepi setebal 2px dengan warna merah (#ff0000) */
    padding: 20px;
    background: #ffffff;
}

/* User Detail style */
.user-detail-container {
    border: 1px solid black;
    max-width: 50%;
    margin: 20px auto;
    padding: 60px;
}
.column-overflow {
    overflow: hidden;
    /* Hide overflow content */
    text-overflow: ellipsis;
    /* Optionally add ellipsis for overflowing text */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

/* style input.input */
.password-mask {
    position: relative;
}

.password-mask::before {
    content: attr(data-password);
    display: inline-block;
    width: 100%;
    background: repeating-linear-gradient(to right,
            #000 0,
            #000 1ch,
            transparent 1ch,
            transparent 2ch);
    text-align: center;
    letter-spacing: 1ch;
    color: transparent;
    overflow: hidden;
    white-space: nowrap;
}

.password-mask span {
    display: none;
    /* Hide actual password text */
}