.radio{
    margin-right: 10px;
}

.checkbox {
  margin-right: 32px; /* Add space between checkboxes */
}

.checkbox input[type="radio"] {
  display: none;
}

.checkbox input[type="radio"] + .checkbox-text {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.checkbox input[type="radio"] + .checkbox-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #000000;
  border-radius: 3px;
  background-color: white;
}

.checkbox input[type="radio"]:checked + .checkbox-text:before {
  background-color: #0082FF;
}

