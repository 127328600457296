.login-container {
    background-color: #005EB8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0;
    padding: 0;
}

.rectangle-container {
    background-color: rgb(255, 255, 255);
    color: black;
    /* Background color for the rectangle */
    padding: 2rem;
    /* Padding inside the rectangle */
    border-radius: 2rem;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Light shadow for effect */
    max-width: 100rem;
    /* Limit the width */
    width: calc(100% - 23.61%);
    /* 100% width minus the left and right margins combined */
    margin: 15vh auto;
    /* Top and bottom: 15vh (15% of viewport height), Left and right: auto to center */
    min-height: 70vh;
    /* Minimum height set to 70% of viewport height */
    display: flex;
    /* Flexbox layout */
    align-items: stretch;
    /* Stretch items to full height */
    justify-content: space-between;
    /* Space between items */
}

.left-side,
.right-side {
    flex: 1;
    /* Ensure both sides take up equal space */
    display: flex;
    flex-direction: column;
}

.left-side {
    padding: 2rem;
    /* Padding for the form area */
    /* justify-content: center; */
}

.right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -32px;
    /* Center the image */
}

.right-side img {
    width: 100%;
    height: 100%;
    /* Ensure image covers the container */
    object-fit: cover;
    /* Ensure image covers area without distortion */
    border-radius: 2rem;
    /* Rounded corners */
}

