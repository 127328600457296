.content {
    margin: 0;
    /* margin-right: 1%; */
    width: calc(100% - 12.5rem); /* 200px to 12.5rem */
    flex: 1; 
}

.fieldd {
    margin: 1.5rem; /* 24px to 1.5rem */
}

/* .content{
    margin: auto;
    width: 50%;
    padding: 10px;
} */

/* Responsive Styles */

/* Tablet Css */
@media screen and (max-width: 768px) { /* 768px to 48rem */
    .content {
        /* margin-left: 10%;
        margin-right: 10%; */
        padding: 0.75rem; /* 12px to 0.75rem */
        width: calc(100% - 1.25rem); /* 20px to 1.25rem */
    }
}

.hidden {
    display: none;
}
/* Handphone Css */
@media screen and (max-width: 480px) { /* 480px to 30rem */
    .content {
        padding: 0.75rem; /* 12px to 0.75rem */
        width: calc(100% - 1.25rem); /* 20px to 1.25rem */
    }
}
