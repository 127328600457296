.custom-button {
    background-color: #005EB8;
    /* Warna latar belakang tombol */
    color: white;
    /* Warna teks tombol */
    padding: 10px 20px;
    /* Padding tombol */
    border: none;
    /* Menghilangkan border */
    border-radius: 4px;
    /* Menambahkan sudut melengkung */
    cursor: pointer;
    /* Mengubah kursor saat hover */
    font-size: 16px;
    /* Ukuran font */
    text-align: center;
    /* Menyelaraskan teks di tengah */
    display: inline-block;
    /* Menampilkan tombol dalam baris */
    transition: background-color 0.3s ease;
    /* Transisi warna latar belakang */
}

.custom-button:hover {
    background-color: #004A9F;
    /* Warna latar belakang saat hover */
}

.custom-select,
.custom-input {
    background-color: white;
    /* Warna latar belakang */
    border: 1px solid #929292;
    /* Warna border */
    border-radius: 4px;
    /* Sudut melengkung */
    padding: 10px;
    /* Padding untuk menambah ruang di dalam */
    font-size: 16px;
    /* Ukuran font */
    color: #333;
    /* Warna teks */
    box-sizing: border-box;
    /* Memastikan padding dan border termasuk dalam ukuran elemen */
    font-weight: normal;
    /* Tidak terlalu tebal */
    display: inline-block;
    /* Menampilkan elemen dalam baris untuk ukuran otomatis */
    min-width: 150px;
    /* Lebar minimum */
    max-width: 100%;
    /* Lebar maksimal sesuai kontainer */
    width: auto;
    /* Lebar otomatis sesuai isi */
    height: 40px;
    /* Tinggi sesuai ukuran tombol */
    line-height: 1.2;
    /* Mengatur tinggi garis untuk tampilan teks */
}

.custom-select {
    appearance: none;
    /* Menghilangkan tampilan default browser untuk select */
}

.custom-input:focus,
.custom-select:focus {
    border-color: #005EB8;
    /* Warna border saat fokus */
    outline: none;
    /* Menghilangkan outline default */
}