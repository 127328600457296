.table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.table th,
.table td {
    position: relative;
    white-space: normal;
    /* Allows text wrapping */
    word-wrap: break-word;
    /* Breaks long words to the next line */
    word-break: break-all;
    /* Breaks the word if it's too long */
    overflow: hidden;
    /* Hide overflow text */
    text-overflow: ellipsis;
    /* Show ellipsis if text overflows */
}

.table td:hover::after {
    content: attr(data-full-text);
    position: absolute;
    left: 0;
    top: 100%;
    white-space: nowrap;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    z-index: 100;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table .action-column {
    width: 150px;
    text-align: center;
    overflow: visible;
}