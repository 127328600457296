.custom-card {
    border: 1px solid #929292;
    height: 60px;
    color: black;
    display: flex;
    align-items: center;
    /* Vertikal center */
    /* justify-content: center; */
    /* Horizontal center */
    padding: 10px;
    /* Optional: padding to add space inside the card */
}
.has-text-custom-blue {
    color: #005EB8;
}

.custom-card.active {
    background-color: #005EB8;
}

.custom-card.active .has-text-custom-blue,
.custom-card.active .svg-icon {
    color: white;
}

.custom-card.active-icon path {
    fill: white;
}
