.login-container {
    background-color: #005EB8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0;
    padding: 0;
}

.rectangle-container {
    background-color: rgb(255, 255, 255);
    color: black;
    /* Background color for the rectangle */
    padding: 2rem;
    /* Padding inside the rectangle */
    border-radius: 2rem;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Light shadow for effect */
    max-width: 100rem;
    /* Limit the width */
    width: calc(100% - 23.61%);
    /* 100% width minus the left and right margins combined */
    margin: 15vh auto;
    /* Top and bottom: 15vh (15% of viewport height), Left and right: auto to center */
    min-height: 70vh;
    /* Minimum height set to 70% of viewport height */
    display: flex;
    /* Flexbox layout */
    align-items: stretch;
    /* Stretch items to full height */
    justify-content: space-between;
    /* Space between items */
}


/* Style for checkboxes */
/* Container for checkboxes */
.checkbox-options-container {
    display: flex; /* Align checkboxes side by side */
    flex-wrap: wrap; /* Allow checkboxes to wrap to the next line */
    gap: 15px; /* Space between checkboxes */
    margin-bottom: 16px; /* Space below the container */
}

/* Style for checkbox labels */
.checkbox-options label {
    display: flex;
    align-items: center; /* Align checkbox and text */
    cursor: pointer; /* Pointer cursor on hover */
}

/* Hide default checkbox */
.checkbox-input {
    appearance: none;
    -webkit-appearance: none;
    width: 20px; /* Size of checkbox */
    height: 20px; /* Size of checkbox */
    background-color: #fff; /* Default background */
    border: 2px solid #ccc; /* Default border */
    border-radius: 4px; /* Rounded corners */
    margin-right: 8px; /* Space between checkbox and label text */
    position: relative; /* For positioning the pseudo-element */
}

/* Style when checkbox is checked */
.checkbox-input:checked {
    background-color: blue; /* Background color when checked */
    border-color: blue; /* Border color when checked */
}

/* Create a custom checkmark */
.checkbox-input:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: blue; /* Fill color */
    border-radius: 2px; /* Square checkmark */
    transform: translate(-50%, -50%);
}
/* Style for textarea */
textarea.input.large-textarea {
    width: 100%; /* Full width of the container */
    height: 150px; /* Height of the textarea */
    padding: 10px; /* Padding inside the textarea */
    border: 1px solid #ccc; /* Border color */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size */
    resize: vertical; /* Allow vertical resizing, remove this if you want to disable resizing */
}
