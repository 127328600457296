/* SidebarStyle.css */

/* Konten di bagian bawah bergeser saat sidebar ditutup */
.content {
    transition: margin-top 0.3s ease;
}

.sidebar.open+.content {
    margin-top: 250px;
    /* Tinggi sidebar */
}

@media (max-width: 768px) {
    .sidebar.open+.content {
        margin-top: 200px;
        /* Tinggi sidebar di media query */
    }
}

/* SidebarStyle.css lainnya */

body {
    font-family: Arial, sans-serif;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    /* Full viewport height */
    width: 250px;
    background-color: #fff;
    border-right: 1px solid #ccc;
    padding-left: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 100vh;
    /* Ensure sidebar doesn't exceed viewport height */
}

.sidebar.open {
    transform: translateY(0);
    /* Slide down from top */
    z-index: 999;
}


.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin: 15px 0;
}

.svg-link-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #333;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.svg-link-container:hover {
    background-color: #f0f0f0;
}

.svg-icon {
    width: 20px;
    height: 20px;
}

.nested-menu {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.nested-menu li {
    margin: 10px 0;
}

.sidebar-toggle {
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.sidebar-toggle svg {
    width: 20px;
    height: 20px;
}

@media (max-width: 768px) {
    .sidebar {
        transform: translateY(-100%);
        /* Slide down from top initially */
    }
}

.blueFont {
    color: #005EB8;
    /* Replace this with your desired color */
}