.card {
    background-color: #ffffff; /* Warna latar belakang */
    border-radius: 8px; /* Sudut rounded */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Efek bayangan */
    padding: 20px; /* Ruang di dalam kartu */
    margin-bottom: 20px; /* Jarak antara kartu */
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 12px;
    /* Jarak antara card summary dan chart jika diperlukan */
}

